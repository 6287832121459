export default {
  "en": {
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
    "13": "13",
    "14": "14",
    "15": "15",
    "16": "16",
    "17": "17",
    "18": "18",
    "19": "19",
    "20": "20",
    "21": "21",
    "22": "22",
    "23": "23",
    "24": "24",
    "25": "25",
    "26": "26",
    "27": "27",
    "28": "28",
    "29": "29",
    "30": "30",
    "31": "31",
    "32": "32",
    "33": "33",
    "34": "34",
    "35": "35",
    "36": "36",
    "37": "37",
    "38": "38",
    "39": "39",
    "40": "40",
    "41": "41",
    "42": "42",
    "43": "43",
    "44": "44",
    "45": "45",
    "46": "46",
    "47": "47",
    "48": "48",
    "49": "49",
    "50": "50",
    "51": "51",
    "52": "52",
    "53": "53",
    "54": "54",
    "55": "55",
    "56": "56",
    "57": "57",
    "58": "58",
    "59": "59",
    "60": "60",
    "61": "61",
    "62": "62",
    "63": "63",
    "64": "64",
    "65": "104",
    "66": "105",
    "67": "67",
    "68": "68",
    "69": "69",
    "70": "70",
    "71": "71",
    "72": "72",
    "73": "73",
    "74": "37",
    "75": "106",
    "76": "107",
    "77": "77",
    "78": "78",
    "79": "79",
    "80": "80",
    "81": "81",
    "82": "82",
    "83": "83",
    "84": "3",
    "85": "85",
    "86": "87",
    "87": "90",
    "88": "88",
    "89": "93",
    "90": "92",
    "91": "84",
    "92": "75",
    "93": "6",
    "94": "33",
    "95": "26",
    "96": "98",
    "97": "96",
    "98": "95",
    "99": "31",
    "100": "38",
    "101": "28",
    "102": "83",
  },
  "es": {
    "1": "1",
    "2": "3",
    "3": "52",
    "4": "4",
    "5": "9",
    "6": "6",
    "7": "5",
    "8": "13",
    "9": "7",
    "10": "8",
    "11": "86",
    "12": "38",
    "13": "45",
    "14": "34",
    "15": "81",
    "16": "35",
    "17": "15",
    "18": "43",
    "19": "37",
    "20": "51",
    "21": "87",
    "22": "29",
    "23": "91",
    "24": "25",
    "25": "70",
    "26": "68",
    "27": "90",
    "28": "20",
    "29": "76",
    "30": "86",
    "31": "102",
    "32": "27",
    "33": "16",
    "34": "93",
    "35": "58",
    "36": "92",
    "37": "30",
    "38": "3",
    "39": "36",
    "40": "21",
    "41": "59",
    "42": "6",
    "43": "2",
    "44": "43",
    "45": "22",
    "46": "19",
    "47": "79",
    "48": "65",
    "49": "105",
    "50": "10",
    "51": "26",
    "52": "41",
    "53": "14",
    "54": "55",
    "55": "25",
    "56": "54",
    "57": "17",
    "58": "18",
    "59": "11",
    "60": "71",
    "61": "77",
    "62": "100",
    "66": "12",
    "67": "53",
    "68": "57",
    "69": "38",
    "70": "40",
    "71": "36",
    "72": "28",
    "73": "93",
    "74": "34",
    "75": "52",
    "76": "98",
    "77": "31",
    "78": "76",
    "79": "46",
    "80": "70",
    "81": "106",
    "82": "12",
    "83": "17",
    "84": "92",
    "85": "102",
    "86": "75",
    "87": "94",
    "88": "69",
    "89": "64",
    "90": "84",
    "91": "80",
    "92": "5",
    "93": "33",
    "94": "98",
    "95": "97",
    "96": "1",
    "97": "2",
    "98": "13",
    "99": "85",
    "100": "10",
    "101": "52",
    "102": "27",
    "103": "81",
    "104": "2",
    "105": "26",
    "106": "31",
    "107": "45",
    "108": "92",
    "109": "29",
    "110": "100",
    "111": "36",
    "112": "82",
    "113": "17",
    "114": "38",
    "115": "46",
    "116": "1",
    "117": "12",
    "118": "6",
    "119": "90",
    "120": "93",
    "121": "49",
    "122": "50",
    "123": "53",
    "124": "103",
    "125": "9",
    "126": "89",
    "127": "105",
    "128": "77",
    "129": "83",
    "130": "99",
    "131": "47",
    "132": "14",
    "133": "102",
    "134": "96",
    "135": "34",
    "136": "20",
    "137": "35",
    "138": "9",
  },
  "fr": {
    "1": "1",
    "2": "3",
    "3": "8",
    "4": "21",
    "5": "20",
    "6": "36",
    "7": "10",
    "8": "4",
    "9": "44",
    "10": "6",
    "11": "13",
    "12": "7",
    "13": "37",
    "14": "102",
    "15": "47",
    "16": "27",
    "17": "48",
    "18": "9",
    "19": "18",
    "20": "14",
    "21": "25",
    "22": "41",
    "23": "100",
    "24": "22",
    "25": "108",
    "26": "24",
    "27": "79",
    "28": "78",
    "29": "35",
    "30": "46",
    "31": "2",
    "32": "82",
    "33": "17",
    "34": "60",
    "35": "49",
    "36": "38",
    "37": "81",
    "38": "39",
    "39": "86",
    "40": "15",
    "41": "26",
    "42": "76",
    "43": "80",
    "44": "100",
    "45": "30",
    "46": "31",
    "47": "21",
    "48": "93",
    "49": "37",
    "50": "92",
    "51": "6",
    "52": "101",
    "53": "105",
    "54": "54",
    "55": "53",
    "56": "8",
    "57": "40",
    "58": "90",
    "59": "4",
    "60": "34",
    "61": "75",
    "62": "7",
    "63": "86",
    "64": "69",
    "65": "18",
    "66": "107",
    "67": "28",
    "68": "27",
    "69": "29",
    "70": "30",
    "71": "37",
    "72": "5",
    "73": "56",
    "74": "57",
    "75": "16",
    "76": "92",
    "77": "46",
    "78": "36",
    "79": "68",
    "80": "84",
    "81": "31",
    "82": "38",
    "83": "95",
    "84": "89",
    "85": "49",
    "86": "28",
    "87": "18",
    "88": "85",
    "89": "104",
    "90": "94",
    "91": "105",
    "92": "101",
    "93": "37",
    "94": "9",
    "95": "51",
    "96": "90",
    "97": "25",
    "98": "92",
    "99": "34",
    "100": "76",
    "101": "22",
    "102": "29",
    "103": "86",
    "104": "38",
    "105": "46",
    "106": "92",
    "107": "70",
    "108": "7",
    "109": "27",
    "110": "2",
    "111": "33",
    "112": "21",
    "113": "15",
    "114": "68",
    "115": "12",
    "116": "42",
    "117": "25",
    "118": "76",
    "119": "34",
    "120": "28",
    "121": "92",
    "122": "27",
    "123": "31",
    "124": "20",
    "125": "86",
    "126": "37",
    "127": "41",
    "128": "13",
    "129": "38",
    "130": "76",
    "131": "101",
    "132": "39",
    "133": "54",
    "134": "28",
    "135": "29",
    "136": "14",
    "137": "30",
    "138": "21",
    "139": "46",
    "140": "52",
  },
  "de": {
    "1": "36",
    "2": "86",
    "3": "6",
    "4": "52",
    "5": "3",
    "6": "4",
    "7": "9",
    "8": "13",
    "9": "36",
    "10": "7",
    "11": "21",
    "12": "8",
    "13": "90",
    "14": "20",
    "15": "37",
    "16": "42",
    "17": "81",
    "18": "15",
    "19": "25",
    "20": "91",
    "21": "34",
    "22": "70",
    "23": "38",
    "24": "54",
    "25": "29",
    "26": "68",
    "27": "35",
    "28": "43",
    "29": "76",
    "30": "21",
    "31": "17",
    "32": "100",
    "33": "58",
    "34": "67",
    "35": "60",
    "36": "2",
    "37": "82",
    "38": "26",
    "39": "47",
    "40": "40",
    "41": "69",
    "42": "22",
    "43": "65",
    "44": "101",
    "45": "55",
    "46": "68",
    "47": "10",
    "48": "73",
    "49": "93",
    "50": "87",
    "51": "92",
    "52": "25",
    "53": "50",
  }
}