<template>
  <div class="clips">
    <home-header></home-header>
    <div class="contentWrap">
      <div class="lessonsWrap" v-loading="loading">
        <template v-for="(lesson, i) in unitsList">
          <div class="lessonWrap" :class="{single: i % 5 === 0}" :key="i">
            <el-popover popper-class="lessonPanel" placement="bottom" width="370" trigger="click" :disabled="!!lesson.lock">
              <div class="medalWrap">
                <template v-for="i in 3">
                  <img v-if="i <= lesson.starNum || 0" class="medalImg" src="@/assets/images/clips/medal.png" :key="i" alt="medal">
                  <img v-else class="medalImg unlock" src="@/assets/images/clips/medalUnlock.png" :key="i" alt="medalUnlock">
                </template>
              </div>
              <div class="sentsWrap">
                <div class="sentWrap" v-for="(sentObj, i) in lesson.lsSents" :key="i">
                  <div class="sent">{{sentObj.sent | textFilter}}</div>
                  <div class="trans">{{sentObj.trans | textFilter}}</div>
                </div>
              </div>

              <div class="btnWrap">
                <template v-if="lesson.free || isBigFans || isMember || testMode">
                  <template v-if="!lesson.progress">
                    <div class="startBtn" @click="startLearnHandle(lesson, i)">{{!userInfo && (i > 2) ? $t('main.login_to_study') : $t('main.enter')}}</div>
                  </template>
                  <template v-else-if="lesson.progress === 1">
                    <div class="startBtn half" @click="startLearnHandle(lesson, i)">{{$t('web.redo')}}</div>
                    <div class="startBtn half review" @click="startLearnHandle(lesson, i, 'review')">{{$t('web.review')}}</div>
                  </template>
                  <template v-else>
                    <div class="startBtn half" @click="startLearnHandle(lesson, i)">{{$t('web.redo')}}</div>
                    <div class="startBtn half continue" @click="startLearnHandle(lesson, i, 'continue')">{{$t('web.continue')}}</div>
                  </template>
                </template>

                <template v-else>
                  <div class="startBtn" @click="buyHandle"><div class="crownIcon"></div>{{$t('buy.unlock_all_clips')}}</div>
                </template>
              </div>

              <div class="lesson" :class="{lock: !!lesson.lock}" @click="lessonClickHandle(!!lesson.lock)" slot="reference">
                <div class="lessonIconWrap">
                  <lottie class="lottie ripple" v-if="isCurrentLesson(lesson.lsID)" :options="rippleOptions" :width="125" :height="125"/>
                  <lottie class="lottie progressBar" v-if="lesson.lessonProgress" :options="progressBarOptions" :width="85" :height="85" @animCreated="handleAnimation($event, lesson.progress)"/>
                  <img class="lessonIconBg" :src="getLessonIconBg(lesson, i)" alt="lessonIconBg">
                  <img class="lessonIcon" :src="getLessonIcon(lesson)" alt="lessonIcon">
                </div>
                <div class="lessonName">{{lesson.lsNm}}</div>
              </div>
            </el-popover>
          </div>
        </template>
      </div>

      <sidebar></sidebar>
    </div>
  </div>
</template>

<script>
  import Vuex from 'vuex'
  import lottie from 'vue-lottie'
  import { getUnitsData } from '@/api/clips'
  import Header from '@/components/Header.vue'
  import Sidebar from '@/components/Sidebar'
  import constants from '@/utils/constants'
  import clipsIconMap from '@/utils/clipsIconMap'
  import * as progressBar from '@/assets/animations/progressBar.json'
  import * as ripple from '@/assets/animations/ripple.json'

  export default {
    data() {
      return {
        unitsData: [],
        loading: false,
        progressBarOptions: { 
          loop: false,
          autoplay: false,
          animationData: progressBar.default 
        },
        rippleOptions: {
          loop: true,
          autoplay: true,
          animationData: ripple.default 
        },
        testMode: constants.testMode
      }
    },
    inject: ['signIn'],
    components: {
      'home-header': Header,
      Sidebar,
      lottie
    },
    computed: {
      ...Vuex.mapGetters(['progressData', 'clipsLessonInfo', 'userInfo', 'userLanguage', 'learnLanguage', 'isMember', 'isBigFans']),
      unitsList() {
        let unitsList = []
        let unitsProgress = []
        if (this.unitsData.length) {
          let langParam = `${this.learnLanguage}pal`  // enpal / espal / frpal...
          let lesson_stars = this.progressData?.[langParam]?.m_movie?.lesson_stars
          let main_tt = this.progressData?.[langParam]?.m_movie?.main_tt
  
          if (lesson_stars && lesson_stars !== '') {
            let lessonStarsArr = lesson_stars.split(';').filter(item => item !== '')
            for (let lessonStars of lessonStarsArr) {
              let lessonID = lessonStars.split(':')[0]
              let starNum = lessonStars.split(':')[1]
              unitsProgress[lessonID] = { starNum: Number(starNum) }
            }
          }
  
          if (main_tt && main_tt !== '') {
            let mainTTArr = main_tt.split(';').filter(item => item !== '')
            for (let mainTT of mainTTArr) {
              let lessonID = mainTT.split(':')[0]
              let lessonProgress = mainTT.split(':')[1]
              let currentProgress = lessonProgress.split('/')[0]
              let totalProgress = lessonProgress.split('/')[1]
              let progress = Number(currentProgress) / Number(totalProgress)
              if (unitsProgress[lessonID]) {
                unitsProgress[lessonID]['lessonProgress'] = lessonProgress
                unitsProgress[lessonID]['progress'] = progress
              } else {
                unitsProgress[lessonID] = { lessonProgress }
                unitsProgress[lessonID] = { progress }
              }
            }
          }

          for (let i = 0; i < this.unitsData.length; i ++) {
            let unit = this.unitsData[i]
            unit.progress = 0
            unit.lock = 1
            if (constants.testMode) {
              unit.lock = 0
            }
            unitsList.push(unit)

            if (unitsProgress[unit.lsID]) {
              Object.assign(unit, unitsProgress[unit.lsID])
            }

            // 前三单元免费
            if (i < 3) {
              unit.free = 1
            }

            if (i === 0) {
              // 第一单元总是解锁的
              unit.lock = 0
            } else {
              // 上一行进度都超过0.3就解锁下一行
              if ((i % 5 === 0) || (i % 5 === 3)) {
                let preUnit1 = this.unitsData[i - 1]
                let preUnit2 = this.unitsData[i - 2]
                if ((preUnit1.progress > 0.3) && (preUnit2.progress > 0.3)) unit.lock = 0
              } else if (i % 5 === 1) {
                let preUnit1 = this.unitsData[i - 1]
                if (preUnit1.progress > 0.3) unit.lock = 0
              } else if (i % 5 === 2) {
                let preUnit2 = this.unitsData[i - 2]
                if (preUnit2.progress > 0.3) unit.lock = 0
              } else if (i % 5 === 4) {
                let preUnit2 = this.unitsData[i - 2]
                let preUnit3 = this.unitsData[i - 3]
                if ((preUnit2.progress > 0.3) && (preUnit3.progress > 0.3)) unit.lock = 0
              }
            }
          }
        }

        return unitsList
      }
    },
    created() {
      this.init()
    },
    filters: {
      textFilter(val) {
        return val.replace(/!@@@!/g, '\n')
      }
    },
    watch: {
      userLanguage() {
        this.init()
      },
      learnLanguage() {
        this.init()
      }
    },
    methods: {
      async init() {
        this.loading = true
        let transList = constants.langsData[this.learnLanguage]['clipsTrans']
        if (transList.includes(this.userLanguage)) {
          let unitsData = await getUnitsData(this.userLanguage, this.learnLanguage)
          this.unitsData = unitsData.filter(unit => unit.lsNm)
        } else {
          this.$store.commit('set_userLanguage', transList[0])
        }
        this.loading = false
      },
      isCurrentLesson(lessonID) {
        let currentLessonID
        if (this.clipsLessonInfo) {
          currentLessonID = this.clipsLessonInfo.lsID
        } else {
          currentLessonID = localStorage.getItem('currentClipsLessonID')
        }
        return lessonID === currentLessonID
      },
      startLearnHandle(lesson, index, type) {
        if (type) {
          lesson.type = type
        }

        // 除了前三章，都需要登录才能学习
        if (!this.userInfo && (index > 2)) {
          this.signIn()
        } else {
          this.$store.commit('set_clipsLessonInfo', lesson)
          this.$router.push('/clips/lesson')
        }
      },
      buyHandle() {
        this.$router.push('/pricing')
      },
      lessonClickHandle(lock) {
        if (lock) {
          this.$message(this.$t('main.please_complete_previous_lesson'))
        }
      },
      getLessonIcon(lesson) {
        let folder = lesson.lock ? 'lessonIconLock' : 'lessonIcon'
        let filename = clipsIconMap[this.learnLanguage][lesson.lsID]
        if (filename) {
          return require(`@/assets/images/clips/${folder}/${filename}@3x.png`)
        }
      },
      getLessonIconBg(lesson, index) {
        let bg
        if (lesson.lock) {
          bg = 'bgLock'
        } else {
          bg = index % 15 >= 10 ? 'bg3' : index % 15 >= 5 ? 'bg2' : 'bg1'
        }
        return require(`@/assets/images/clips/lessonIconBg/${bg}.png`)
      },
      handleAnimation(anim, progress) {
        anim.goToAndStop(progress * 94, true)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .clips {
    background: #F3F4F8;
    min-height: 100vh;
    .contentWrap {
      display: flex;
      justify-content: space-between;
      margin: 80px auto 0;
      padding: 14px 0;
      width: 1000px;
      .lessonsWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px 100px 30px;
        width: 680px;
        background: #FFFFFF;
        border-radius: 12px;
        .lessonWrap {
          width: 50%;
          margin-top: 30px;
          &.single {
            width: 51%;
          }
          .lesson {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.lock {
              cursor: unset;
              opacity: 0.5;
            }
            .lessonIconWrap {
              position: relative;
              width: 85px;
              height: 85px;
              .lessonIcon {
                position: absolute;
                top: 2px;
                left: 2px;
                width: 81px;
                z-index: 3;
              }
              .lessonIconBg {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                z-index: 2;
              }
              .lottie {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 3;
                &.ripple {
                  z-index: 1;
                  left: -20px;
                  top: -20px;
                }
              }
            }
            .lessonName {
              margin-top: 5px;
              font-weight: 500;
              font-size: 16px;
              color: #000000;
              text-align: center;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .clips {
      .contentWrap {
        flex-direction: column;
        width: 100%;
        padding: 0;
        .lessonsWrap {
          padding: 0 0 30px;
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
</style>

<style lang="scss">
  .lessonPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 360px;
    padding: 0;
    border: 1px solid transparent;
    border-radius: 12px;
    background: #2F7ABD;
    .popper__arrow {
      border-top-color: #2F7ABD !important;
      border-bottom-color: #2F7ABD !important;
      &:after {
        border-top-color: #2F7ABD !important;
        border-bottom-color: #2F7ABD !important;
      }
    }
    .medalWrap {
      margin-top: 20px;
      width: 149px;
      height: 34px;
      display: flex;
      justify-content: space-between;
      .medalImg {
        width: 27px;
      }
    }
    .sentsWrap {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 98%;
      height: 220px;
      overflow-y: auto;
      .sentWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 0;
        width: 80%;
        border-bottom: 1px solid #FFFFFF33;
        .sent {
          font-weight: 600;
          font-size: 14px;
          color: #FFFFFF;
          white-space: pre-line;
        }
        .trans {
          font-weight: 500;
          font-size: 12px;
          color: #FFFFFF;
          white-space: pre-line;
        }
      }
    }
    .btnWrap {
      margin-top: 15px;
      width: 80%;
      display: flex;
      justify-content: space-between;
      .startBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 10px;
        width: 100%;
        height: 40px;
        line-height: 20px;
        background: #FFFFFF;
        border-radius: 8px;
        font-weight: 900;
        font-size: 16px;
        color: #004E94;
        cursor: pointer;
        transition: all .3s;
        &:hover {
          opacity: 0.8;
        }
        &.half {
          width: 47%;
        }
        &.review, &.continue {
          color: #4388FF;
        }
        .crownIcon {
          margin-right: 5px;
          width: 24px;
          height: 20px;
          mask-size: cover;
          mask-image: url('~@/assets/images/courses/unit/crown.png');
          background: #004E94;
        }
      }
    }
  }
</style>